import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';

import { Image } from '../types';

export const contactData = (): { image: Image } => {
  const result = useStaticQuery(graphql`
    query {
      contactImage: file(sourceInstanceName: { eq: "contact" }) {
        id
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 700)
        }
      }
    }
  `);
  const image = {
    key: result.contactImage.id,
    gatsbyImage: result.contactImage.childImageSharp.gatsbyImageData,
    src: getSrc(result.contactImage.childImageSharp.gatsbyImageData) ?? '',
    originalName: result.contactImage.name,
  };
  return { image };
};
